import React, { useEffect } from "react"
import { Text } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1 } from "../components/Typography"

export default function Disclaimer() {
  useEffect(() => {
    firebase.analytics().setCurrentScreen("classifieds_disclaimer")
  }, [])
  return (
    <Layout p={[4, 6]}>
      <SEO title="Disclaimer" />
      <H1 textAlign="center">Classified Ads Disclaimer</H1>
      <Text fontSize="lg" mt={20} textAlign="justify">
        We reserve the right to edit, categorize, revise, or to decline any
        classified advertisement. As it is our intent to uphold the highest
        standards of accuracy, clarity and legality, we will contact you for
        clarification of any ad that compromises this intent. Check your ad and
        if you find an error, please report it immediately to us. We will make
        every effort to ensure your ad is correct, however, Laudium Today will
        not be held liable for any damages arising out of errors or omissions.
        Please note, Classified Ads are a FREE service, and we do not assume
        responsibility for the products or services sold or offered. It is
        responsibility of individuals who posted the ad to reply to your
        message, and confirm the legitimacy. There are risks which you assume
        when dealing with people who might be acting under false pretenses; all
        of these risks are borne by You. We are a venue only and do not control
        the products or services offered to other Users, nor does Laudium Today
        control the users of our Classified service.
      </Text>
    </Layout>
  )
}
